import { Controller } from "@hotwired/stimulus";

// Confirmation dialog using window.confirm,
// use in place of old rails UJS: data: { confirm: "" }
export default class extends Controller {
  static values = { message: String };

  confirm(event) {
    if (!window.confirm(this.messageValue)) {
      event.preventDefault();
    }
  }
}
