////////////////////////////////////////////////////////////////////////////////
// javascript for non-Elm pages, runs turbo / stimulus
////////////////////////////////////////////////////////////////////////////////
import "@hotwired/turbo-rails";

import { Application } from "@hotwired/stimulus";
import CharacterLimitedController from "./controllers/character_limited_controller";
import ConfirmController from "./controllers/confirm_controller";
import FlashController from "./controllers/flash_controller";
import SearchFormController from "./controllers/search_form_controller";
import SidebarController from "./controllers/sidebar_controller";
import SlideShowController from "./controllers/slideshow_controller";
import LocalTime from "local-time";
import RecipeViewPrefsController from "./controllers/recipe_view_prefs_controller";
import feather from "feather-icons";
import "trix";
import "@rails/actiontext";

(function () {
  ////////////////////////////////////////////////////////////////////////////////
  // STIMULUS
  const stimulus = Application.start();
  stimulus.register("search-form", SearchFormController);
  stimulus.register("recipe-view-prefs", RecipeViewPrefsController);
  stimulus.register("character-limited", CharacterLimitedController);
  stimulus.register("confirm", ConfirmController);
  stimulus.register("flash", FlashController);
  stimulus.register("slideshow", SlideShowController);
  stimulus.register("sidebar", SidebarController);
  ////////////////////////////////////////////////////////////////////////////////

  LocalTime.start();

  ["turbo:render", "turbo:frame-render", "turbo:load"].forEach((turboEvent) => {
    document.addEventListener(turboEvent, feather.replace);
  });

  // add target="_blank" to article links
  document.querySelectorAll(".trix-content div a").forEach((link) => {
    link.setAttribute("target", "_blank");
  });
})();
