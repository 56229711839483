import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="character-limited"
export default class extends Controller {
  static values = { limit: Number };
  static targets = ["input", "counter"];

  connect() {
    this.limitValue = this.limitValue || 1024;
    this.updateCounter();
  }

  updateCounter() {
    const currentLength = this.inputTarget.value.length;
    this.counterTarget.textContent = `${currentLength}/${this.limitValue}`;
  }

  checkLength() {
    if (this.inputTarget.value.length > this.limitValue) {
      this.inputTarget.value = this.inputTarget.value.substring(0, this.limitValue);
    }
    this.updateCounter();
  }
}
