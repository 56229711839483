import { Controller } from "@hotwired/stimulus";
import debounce from "lodash.debounce";

export default class extends Controller {
  static targets = ["form"];

  connect() {
    this.debouncedApply = debounce(this.apply.bind(this), 1500);
  }

  apply(event) {
    event.preventDefault();

    const form = this.formTarget;
    const url = new URL(form.action);
    const params = new URLSearchParams(new FormData(form));

    // Append query parameters for GET request
    url.search = params.toString();

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
      credentials: "same-origin",
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          console.error("Network error:", response.statusText);
        }
      })
      .then((turboStreamHTML) => {
        if (turboStreamHTML) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(turboStreamHTML, "text/html");

          // Extract and process turbo-stream elements
          doc.querySelectorAll("turbo-stream").forEach((streamElement) => {
            const targetId = streamElement.getAttribute("target");
            const action = streamElement.getAttribute("action");
            const templateContent =
              streamElement.querySelector("template").content;

            const targetElement = document.getElementById(targetId);
            if (targetElement && action === "replace") {
              targetElement.innerHTML = "";
              targetElement.appendChild(templateContent);
            } else {
              console.log(
                "No matching frame found or action not replace for:",
                targetId
              );
            }
          });
        }
      });
  }

  // New method to handle input events with conditional debouncing
  handleInput(event) {
    if (event.target.id === "recipe_view_prefs_mash_eff") {
      this.debouncedApply(event);
    } else {
      this.apply(event);
    }
  }
}
