import { Controller } from "@hotwired/stimulus";

import feather from "feather-icons";

// Displays a slide show
// For lazy loading, images should use data-src instead of src
// The first image is loaded immediately; remaining images
// are loaded while the frame immediately before them is displayed
export default class extends Controller {
  static targets = ["slide"];

  // holds ID for interval timeout
  intervalID = null;

  // when false, slideshow is paused
  isPlaying = true;

  connect() {
    this.currentIndex = 0;
    // need to immediately load first image
    this.imageLoad(0);
    this.slideSet(this.currentIndex);
    this.intervalStart();
  }

  disconnect() {
    this.intervalStop();
  }

  // for each frame, the next image is loaded, but for the first frame
  // we need to load the image immediately on connect
  imageLoad(index) {
    const firstImage = this.slideTargets[index].querySelector("img");
    if (firstImage && !firstImage.src) {
      firstImage.src = firstImage.dataset.src;
    }
  }

  indexNext = (index) => (index + 1) % this.slideCount();

  indexPrev(index) {
    const count = this.slideCount();
    return (index - 1 + count) % count;
  }

  intervalReset() {
    if (this.isPlaying) {
      this.intervalStop();
      this.intervalStart();
    }
  }

  intervalStart() {
    this.intervalID = setInterval(() => this.next(), 3000);
  }

  intervalStop() {
    clearInterval(this.intervalID);
  }

  intervalToggle() {
    this.isPlaying = !this.isPlaying;
    this.playBtnUpdate();
    this.isPlaying ? this.intervalStart() : this.intervalStop();
  }

  next() {
    this.currentIndex = this.indexNext(this.currentIndex);
    this.slideSet(this.currentIndex);
    this.intervalReset();
  }

  playBtnUpdate() {
    const playBtn = this.element.querySelector(
      '[data-action="click->slideshow#intervalToggle"]'
    );
    playBtn.innerHTML = this.isPlaying
      ? `<i data-feather="pause"></i> Pause`
      : `<i data-feather="play"></i> Play`;
    feather.replace();
    // playBtn.textContent = this.isPlaying ? "Pause" : "Play";
  }

  previous() {
    this.currentIndex = this.indexPrev(this.currentIndex);
    this.slideSet(this.currentIndex);
    this.intervalReset();
  }

  slideCount = () => this.slideTargets.length;

  slideSet(index) {
    this.slideTargets.forEach((el, i) => {
      const visible = i === index;
      el.classList.toggle("block", visible);
      el.classList.toggle("hidden", !visible);
    });

    this.imageLoad(this.indexNext(index));
  }
}
