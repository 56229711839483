import { Controller } from "@hotwired/stimulus";

// close flash messages in response to a click
export default class extends Controller {
  static targets = ["flashMsg"];

  remove(event) {
    event.currentTarget.remove();
  }
}
