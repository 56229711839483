import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "sidebar",
    "backdrop",
    "toggleButtonText",
    "toggleButtonIcon",
  ];

  toggle() {
    this.sidebarTarget.classList.toggle("-translate-x-full");
    this.backdropTarget.classList.toggle("hidden");
    this.updateButton();
  }

  close() {
    this.sidebarTarget.classList.add("-translate-x-full");
    this.backdropTarget.classList.add("hidden");
    this.updateButton();
  }

  updateButton() {
    const isOpen = !this.sidebarTarget.classList.contains("-translate-x-full");
    this.toggleButtonTextTarget.textContent = buttonText(isOpen);
    this.toggleButtonIconTarget.innerHTML = buttonIcon(isOpen);
  }

  buttonIcon(isOpen) {
    return isOpen ? this.closeIcon() : this.menuIcon();
  }

  buttonText(isOpen) {
    return isOpen ? "CLOSE" : "MENU";
  }

  closeIcon() {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    `;
  }
  menuIcon() {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
      </svg>
    `;
  }
}
