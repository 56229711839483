import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="search-form"
export default class extends Controller {
  // stimulus creates a this.formTarget which returns first matching target
  // targets are specified with data tags, in this case:
  // data: { search_form_target: "form" }
  static targets = ["input", "form"];

  connect() {
    this.inputTarget.focus();
  }

  search() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.formTarget.requestSubmit(), 500);
  }

  selected() {
    this.formTarget.requestSubmit();
  }
}
